import React, { FC } from "react";
import { Switch, Route } from "react-router-dom"

import Home from "pages/Home";

type Props = {}
const Router: FC<Props> = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  )
}

export default Router;