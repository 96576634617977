import React, { FC } from "react";
import { Container, styled } from "@mui/material";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: "80px 0px 100px",
  [theme.breakpoints.down("sm")]: {
    padding: "50px 15px 100px",
  },
}));

const Header = styled("span")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "70px"
});

const Image = styled("img")(({ theme }) => ({
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    width: "200px",
  },
}))

const SheepWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block"
  },
}))

const SheepImage = styled("img")(({ theme }) => ({
  width: "50%",
  height: "300px",
  objectFit: "cover",
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  },
}))

type Props = {}
const Home: FC<Props> = (props) => {

  return (
    <StyledContainer maxWidth="md">
      <Header>
        <Image src="public-circle.svg" />
      </Header>

      <div style={{ marginBottom: "60px" }}>
        <img src="people.jpg" style={{ width: "100%", height: "300px", objectFit: "cover", borderRadius: "4px" }} />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <h1 style={{ letterSpacing: "-3px", fontWeight: "900", marginTop: '5px', marginBottom: '0px', color: "#0043ff" }}>MISSION : FOR THE PUBLIC</h1>
        </div>
      </div>

      <div style={{ marginBottom: "60px" }}>
        <div style={{ backgroundColor: "#FFE70B", padding: "8px 10px", borderRadius: '4px', display: "inline-block", marginBottom: "15px" }}>
          <h2 style={{ letterSpacing: "-2px", margin: "0px" }}>会社概要</h2>
        </div>

        <div>
          <ul>
            <li style={{ fontWeight: "bold", letterSpacing: "-1px" }}>社名 : PUBLIC株式会社</li>
            <li style={{ fontWeight: "bold", letterSpacing: "-1px" }}>代表 : 松本拓也</li>
            <li style={{ fontWeight: "bold", letterSpacing: "-1px" }}>設立 : 2021年2月</li>
            <li style={{ fontWeight: "bold", letterSpacing: "-1px" }}>住所 : 〒150-0032 東京都渋谷区鶯谷4-21 エルカーサ1C</li>
            <li style={{ fontWeight: "bold", letterSpacing: "-1px" }}>事業 : インターネットサービスの企画・運営</li>
          </ul>
        </div>
      </div>

      <div style={{ marginBottom: "60px" }}>
        <div style={{ backgroundColor: "#FFE70B", padding: "8px 10px", borderRadius: '4px', display: "inline-block", marginBottom: "15px" }}>
          <h2 style={{ letterSpacing: "-2px", margin: "0px" }}>事業内容</h2>
        </div>

        <div style={{ marginBottom: "50px" }}>
          <p style={{ fontWeight: "bold", letterSpacing: "-1px" }}>1. ライブコマースアプリ「<a href="https://drop.ec" target="_blank" style={{ fontWeight: "bold" }}>ドロップ (https://drop.ec)</a>」の開発・運営</p>

          {/* <img src="service.svg" style={{ width: "100%", marginBottom: "15px" }} /> */}
          <a href="https://drop.ec" target="_blank">
            <img src="service.png" style={{ width: "100%", marginBottom: "15px" }} />
          </a>

          <p style={{ fontWeight: "bold", letterSpacing: "-1px" }}>LIVE FOR PEOPLE - 「好き」が、もっと好きになる出会い</p>
          <p style={{ fontWeight: "bold", letterSpacing: "-1px" }}>「好き」と「情熱」がもっと伝わり、誰もが商売できる場所をつくる。そんな場所を通じて、新たな「出会い」を提供することで、人と人、人と物をつなぎ、より多くの人の世界を広げるお手伝いをしたいと思っています。</p>
        </div>

        <p style={{ fontWeight: "bold", letterSpacing: "-1px" }}>2. in development ...</p>
      </div>

      {/* <div style={{ marginBottom: "60px" }}>
        <div style={{ backgroundColor: "#FFE70B", padding: "8px 10px", borderRadius: '4px', display: "inline-block", marginBottom: "15px" }}>
          <h2 style={{ letterSpacing: "-2px", margin: "0px" }}>採用情報</h2>
        </div>

        <p style={{ fontWeight: 'bold', letterSpacing: "-1px" }}>一緒に働く仲間を募集しています。お問い合わせよりお気軽にご連絡くださいませ。</p>
      </div> */}

      <div style={{ marginBottom: "60px" }}>
        <div style={{ backgroundColor: "#FFE70B", padding: "8px 10px", borderRadius: '4px', display: "inline-block", marginBottom: "15px" }}>
          <h2 style={{ letterSpacing: "-2px", margin: "0px" }}>お問い合わせ</h2>
        </div>

        <p style={{ fontWeight: "bold", letterSpacing: "-1px" }}>以下URLよりお気軽にご連絡くださいませ。通常2,3日以内にご返信をさせていただいております。</p>
        <p style={{ fontWeight: 'bold', letterSpacing: "-1px" }}><a href="https://forms.gle/f1AGhX1G4aZLDMDa7" target="_blank">https://forms.gle/f1AGhX1G4aZLDMDa7</a></p>
      </div>

      <SheepWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <h1 style={{ letterSpacing: "-3px", fontWeight: "900", marginTop: '5px', marginBottom: '0px', color: "#000" }}>THANKS FOR VISITING!</h1>
        </div>
        <SheepImage src="sheep.jpg" />
      </SheepWrapper>

    </StyledContainer>
  )

}

export default Home