import React from 'react';
import './App.css';
import theme from "theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Router from "components/Router";
import { CssBaseline } from "@mui/material";

function App() {
  return (

    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
