import { createTheme } from "@mui/material/styles";

const fontFamily = '"Noto Sans JP", sans-serif';

let theme = createTheme();
theme = createTheme(theme, {
  typography: {
    fontFamily,
  },
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
